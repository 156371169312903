
.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal {
  background: linear-gradient(135deg, #8936cd, #5a2494);
  color: white;
  font-weight: 500;
  border-radius: 20px;
  padding: 40px;
  width: 400px;
  max-width: 90%;
  position: relative;
  display: flex;
  font-family: 'Avenir';
  flex-direction: column;
  justify-content: space-between;
}

.modal h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 30px;
  font-family: 'Avenir';
  margin-top: 10px;
}

.login-form input {
  width: 100%;
  border: none;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 10px;
}

.login-form button[type="submit"] {
  background: linear-gradient(45deg, #ffffff, #f3f3f3);
  color: rgb(19, 19, 19);
  font-weight: 550;
  font-family: 'Avenir';
  border-radius: 20px;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  margin-top: 10px;
}
.login-form button:hover {
  box-shadow: 0 0 8px #fdfaff41, 0 0 10px #f1e5ff44, 0 0 12px  #9f48fc43; /* Adjust as needed */
  transform: scale(1.05);         /* Enlarges the image slightly when hovered */
}

.registered-link {
  color: #ffffff;
  text-decoration: none;
  margin-bottom: 30px;
  font-size: 14px;
  display: inline-block;
}

.registered-link:hover {
  color: #fff427;
}

.forgot-container {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.forgot-link {
  color: #ffffff; /* default color */
  text-decoration: none;
  font-size: 14px;
}

.forgot-link:hover {
  color: #fff427; /* color when mouse is over the link */
}

.need-account {
  font-size: 14px; /* Adjust size as needed */
  margin-bottom: 20px;
  padding: 5px;
}

.account-link {
  color: #ffffff; /* default color */
  text-decoration: underline;
  border: none;
  outline: none;
  cursor: pointer;
  font-weight: 550;
  margin-left: 5px;
  font-size: 14px;
}

.account-link:hover {
  color: #fff427; /* color when mouse is over the link */
}

.close-button {
  background: transparent;
  color: lightgray;
  border: none;
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}
@media (max-width: 390px) {
  .need-account {
    font-size: 13px; /* Adjust size as needed */
    margin-bottom: 20px;
    padding: 5px;
  }
  .forgot-link {
    color: #ffffff; /* default color */
    text-decoration: none;
    font-size: 13px;
    margin-bottom: 20px;
  }
  .modal {
    min-height: 55vh;
  }
  .modal h2 {
    margin-top: 25px;
    margin-bottom: 12px;
  }

}