
.input-form {
    background-color:  #181818;
    padding: 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 30%;
    overflow-y: scroll;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
}

.form-group input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.button-group {
    display: flex;
    justify-content: space-between;
}

.button-group button {
    width: 48%; /* Makes both buttons the same size while accommodating the gap between them */
    padding: 10px;
    border: none;
    background-color: #007BFF;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.button-group button:hover {
    background-color: #0056b3;
}
.customize-select {
    width: 100%;
    appearance: none;
    webkit-apperance: none;
    padding: 12px;
    outline: none;
    margin-bottom: 10px;
    border: 1px solid #352c52;
    border-radius: 7px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -.01em;
    color: #fff;
    position: relative;
    text-align: left;
    background-color: #41326a;
    padding: 10px;
    padding-right: 30px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyOTIuNCIgaGVpZ2h0PSIyOTIuNCI+PHBhdGggZmlsbD0iIzAwN0NCMiIgZD0iTTI4NyA2OS40YTE3LjYgMTcuNiAwIDAgMC0xMy01LjRIMTguNGMtNSAwLTkuMyAxLjgtMTIuOSA1LjRBMTcuNiAxNy42IDAgMCAwIDAgODIuMmMwIDUgMS44IDkuMyA1LjQgMTIuOWwxMjggMTI3LjljMy42IDMuNiA3LjggNS40IDEyLjggNS40czkuMi0xLjggMTIuOC01LjRMMjg3IDk1YzMuNS0zLjUgNS40LTcuOCA1LjQtMTIuOCAwLTUtMS45LTkuMi01LjUtMTIuOHoiLz48L3N2Zz4=);
    background-repeat: no-repeat,repeat;
    background-position: right 0.7em top 50%,0 0;
    background-size: 0.65em auto,100%;
}
.customize-label{
    display: inline-block;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -.01em;
    color: rgba(239,239,239,.81);
    margin-bottom: 4px;
}
.ribbon-overlay {
    display: flex;
    align-items: center;
    top: 50%;
    width: 100%;
}
.ribon-text {
    font-size: 14px;
    color: #fff;
    line-height: 2;
    margin-left: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    text-transform: capitalize;
}
.step-icon {
    max-height: 14px;
    margin-top: 0px;
}
#interior-box {
    height: 100%;
    overflow-y: scroll;
    height: auto;
    border: none;
    padding: 20px;
    margin-bottom: 15px;
    flex-grow: 1; /* Makes this div take all available space */
    overflow-y: auto;
  }
  #interior-box::-webkit-scrollbar {
    width: 13px;
}

    #interior-box::-webkit-scrollbar-thumb {
        background-color: #313131;
        border-radius: 7.5px; /* Optional, to make the thumb corners rounded */
    }

    #interior-box::-webkit-scrollbar-track {
        background-color: #1d1d1d;
    }

    #interior-box img {
        width: 385px;
    }
  .rightside {
    flex-grow: 1;  /* Allows the right side to take up the remaining space */
    display: flex;
    justify-content: flex-start; 
    background-color: #1d1d1d; 
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    overflow: auto;  /* Prevents the right side from expanding beyond its container */
    height: 100vh;
  }
  #interior-box {
    display: grid;
    grid-template-rows:  1fr 1fr; /* Creates a new row for each new pair */
 }

 .grid {
    display: grid;
    grid-template-rows: 1fr 1fr;  /* This makes it two images per row */
    gap: 20px;  /* Adjust gap as necessary */
}

.row-container {
    display: flex;
    flex-direction: row; /* Stacks children vertically */
    gap: 10px;
}

.image-container {
    position: relative; /* So that floating elements can be positioned relative to this container */
    width: 100%; 
}

.user-input,
.bot-interior-image {
    width: 100%;
    vertical-align: middle;
    display: flex;
    align-items: flex-start;
    transition: all 0.2s ease;
    border-radius: 25px;
    height: 100%;
    object-fit: cover; /* Image will cover the entire container and maintain its aspect ratio */
    object-position: center;
    margin-bottom: 10px; /* Adjusted margin */
}

.magnifier-icon{
    border-radius: 8px;
    background: rgba(0,0,0,.6);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
    border: none;
    width: 40px !important;
    padding: 6px;
}
.download-icon{
    border-radius: 8px;
    background: rgba(0,0,0,.6);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
    border: none;
    width: 40px !important;
    padding: 6px;
}
.image-icons{
    display: flex;
    flex-direction: column;
    position: absolute;  /* Position it above the image */
    justify-content: space-between;
    gap: 5px;
    top: 15px;           /* Offset from the top */
    right: 25px;
    z-index: 10;
}

.view-image-btn {
    padding: 2px;     /* Offset from the right */
    background: none;    /* No background for the button */
    border: none;        /* Remove default border */
    opacity: 1;          /* Initially hidden */
    transition: opacity 0.3s ease;  /* Smooth fade in transition */
    cursor: pointer;     /* Ensure it's clickable */
    z-index: 10;
}
.dwn-image-btn {
    padding: 2px;     /* Offset from the right */
    background: none;    /* No background for the button */
    border: none;        /* Remove default border */
    opacity: 1;          /* Initially hidden */
    transition: opacity 0.3s ease;  /* Smooth fade in transition */
    cursor: pointer;     /* Ensure it's clickable */
    z-index: 10;
}
.bot-image-container:hover .view-image-btn {
    opacity: 1;  /* Show the button on hover */
}
.title1{
    display: flex;
    font-size: 18px;
    font-weight: 600;
    color: white;
}
.title2{
    display: flex;
    margin-top: 3px;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 400;
    color: rgb(121, 121, 121)
}
.file_uploader_placeholder {
    display: block;     
    align-items: center; 
    justify-content: center;    /* Change from inline-block to block */
    width: 100%;             /* Set width to 100% */
    padding: 30px 15px;     /* Increased vertical padding (top and bottom) */
    border: 2px dashed #7527a2;
    color: white;
    background-color: #232323;
    border-radius: 25px;
    text-align: center;
    cursor: pointer;
    position: relative;
    transition: background-color 0.3s ease;
}


.file_uploader_placeholder:hover {
    background-color: rgba(155, 40, 217, 0.1);
}

.file_uploader_placeholder svg {
    width: 50px;  /* Adjust as necessary */
    height: 50px; /* Adjust as necessary */
    margin-bottom: 10px;
}

.file_uploader_placeholder span {
    display: block;
    font-weight: 400;
    font-size: 14px;
}
.floating_infos {
    position: absolute;
    bottom: 0;
    z-index: 10;
    background: rgba(0,0,0,.4);
    color: #fff;
    padding: 4px 8px 4px 15px;
    border-radius: 0px 8px 0px 25px;
    font-size: 12px;
    font-weight: 500;
}

/* Define keyframes for the dot animation */
@keyframes dotPulse {
    0%   { opacity: 0.5; }
    50%  { opacity: 1; }
    100% { opacity: 0.5; }
  }
  .loading-icon {
    display: flex;
    position: relative;
    height: 5px;
    padding:8px;
    max-width: 5px;
  }
  
  .loading-icon:before,
  .loading-icon:after,
  .loading-icon span {
    position: absolute;
    top: 0;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: white;  /* you can adjust the color */
    content: '';
    animation: dotPulse 1s infinite;
  }
  .loading-animation {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* this will center the animation */
    /* if you want to adjust the z-index to make sure it appears on top */
    z-index: 10;
}
.loading-icon span { left: 8px; }

.loading-icon:before {
  left: -10px;
  animation-delay: -0.25s;  /* delay for the first dot */
}

.loading-icon:after {
  left: 26px;
  animation-delay: 0.25s;  /* delay for the last dot */
}


@media screen and (max-width: 767px){
.input-form , #interior-box {
    width: 100%;
    background-color: #1d1d1d;
    display: flex;
    flex-direction: column;
}
#interior-box {
    display: flex;
    flex-direction: row;
    max-width: 100%;
    height: auto;
    overflow-y: visible;
}
.input-form{
    padding: 20px;
    height: auto;
    overflow-y: visible !important;
}
.grid {
    display: flex;
    flex-direction: column;
    max-width: 100%;
}
.row-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.rightside{
    display: flex;
    flex-direction: column;
    height: 100vh;
    padding: 10px;
    position: relative;
    width: 100%;
}

#interior-box img {
    width: 100%;
}
.user-column,
.bot-column {
    flex: 1; /* Takes up half of the available width each */
    padding: 0px;
}
.title2{
    margin-bottom: 5px;
}
.title1{
    margin-top: 25px;
}
@keyframes dotPulse {
    0%   { opacity: 0.5; }
    50%  { opacity: 1; }
    100% { opacity: 0.5; }
  }
  .loading-icon {
    display: flex;
    position: relative;
    height: 5px;
    padding:8px;
    max-width: 5px;
  }
  
  .loading-icon:before,
  .loading-icon:after,
  .loading-icon span {
    position: absolute;
    top: 0;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: white;  /* you can adjust the color */
    content: '';
    animation: dotPulse 1s infinite;
  }
  .loading-animation {
    position: absolute;
    margin-top: 40px;
    margin-bottom: 40px;
    align-items: center;
    z-index: 10;
}
.loading-icon span { left: 8px; }

.loading-icon:before {
  left: -10px;
  animation-delay: -0.25s;  /* delay for the first dot */
}

.loading-icon:after {
  left: 26px;
  animation-delay: 0.25s;  /* delay for the last dot */
}
.magnifier-icon{
    display: none;
}

}