
.confirmation {
    display: flex;
    background-color: #1A2234; /* Your primary background color */
    background-size: cover;
    background-position: center;
    background-attachment: scroll;
    align-items: center;
    justify-content: center;
    height: 100vh;
    flex-direction: column;
    max-height: 100%;
    width: 100%;
    font-family: 'Avenir';
    padding: 20px;
}

.confirmation::before {
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('./images/body/glow-bottom.svg');
    background-attachment: scroll;
    background-size: cover;
    opacity: 1;
    background-position: center;
    position: absolute;
    z-index: 0;
  }

.confirmation h1 {
    color: #fff; /* Green color for a successful payment message */
    font-size: 2.5rem;
    display: flex;
    margin-bottom: 40px;
    align-items: center;
}

.confirmation p {
    font-size: 1.2rem;
    text-align: center;
    max-width: 80%;
    margin-bottom: 40px;
    color: #c1c1c1;
}

.confirmation button {
    display: inline-block;
    background: linear-gradient(45deg, #cc23d2, #b201ac);    border: none;
    margin-top: 20px;
    padding: 12px 20px;
    color: #fff;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.2s ease;
    border-radius: 25px;
    outline: none;
}

.confirmation button:hover {
    background: #04cb8f;  /* This overrides the gradient */
    transform: scale(1.1);         /* Enlarges the image slightly when hovered */
}
@keyframes glitter {
    0% { opacity: 1; }
    50% { opacity: 0.5; }
    100% { opacity: 1; }
}

.star {
    animation: glitter 1s infinite alternate; /* increased duration from 0.5s to 1s */
}




@media screen and (max-width: 500px) and (max-height: 798px) { 

    .confirmation h1 {
        color: #fff; /* Green color for a successful payment message */
        font-size: 1.8rem;
        display: flex;
        margin-bottom: 40px;
        align-items: center;
    }
    .confirmation {
        overflow-y: hidden;

    }
    
}
