/* Launcher.css */
html, body {
  height: auto;
  min-height: 100%;
  overflow-y: auto;
  font-family: 'Avenir';
}
.route-container {
  position: relative;
  z-index: 1;
  height: 100vh;
  font-family: 'Avenir';
}
  
.launcher {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  height: 100%;
  background-color: #1A2234; /* Your primary background color */
}

.launcher::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('./images/body/glow-top.svg');
  background-size: cover;
  opacity: 0.85;
  background-position: center;
  z-index: 0; /* Ensure the image is behind the content */
}


  
  .launcher-header {
    padding: 20px;
    text-align: center;
    margin-bottom: 30px;
    z-index: 1; /* Ensure the image is behind the content */
  }
  
  .launcher-menu {
    grid-gap: 12px;
    display: grid;
    gap: 12px;
    grid-template-columns: repeat(4,280px);
    margin-top: 60px;
    z-index: 1; /* Ensure the image is behind the content */
  }
  
  .launcher-item {
    -webkit-animation: puff-in-center .3s cubic-bezier(.47,0,.745,.715) both;
    animation: puff-in-center .3s cubic-bezier(.47,0,.745,.715) both;
    background: var(--gray-50, #ffffff11); /* Backup color in case --gray-50 is not defined */
    border-radius: 20px;
    min-height: 400px;
    padding: 32px;
    color: white;
    transition: background .1s ease-in-out;
    cursor: pointer; /* To indicate it's clickable */
    margin-bottom: 16px; /* To separate the items a bit */
  }
  .launcher-body {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
  .launcher-item-desc {
    margin-top: 20px;
    color: #b1b1b1; 
  }
  .launcher-item-desc2 {
    margin-top: 30px;
    color: #df1be6; 
    font-weight: 600;
    display: flex;
    align-items: center;
  }
  .launcher-item-desc2:hover {
    color: rgb(0, 174, 255);
  }
  .launcher-item:hover {
    background: #ffffff4b; /* Slightly darker shade on hover */
    color: black;
  }
  .launcher-item-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    animation: scale-in-center .5s cubic-bezier(.25,.46,.45,.94) both;
    flex: 0 1;
  }
  
  /* Additional animation you mentioned */
  @keyframes puff-in-center {
    0% {
      transform: scale(2);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  
@media screen and (max-width: 700px) and (max-height: 1000px) { 

  .launcher {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background-attachment: scroll;
    max-width: 100%;
    min-height: 100%;
    padding: 40px 0;
    width: 100%;
  }
    .launcher-body {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
  }
  .launcher-menu {
    grid-template-columns: repeat(1,295px);
    margin-top: 32px;
  }
  .launcher-item {
    min-height: 0;
}
.launcher-item-header {
  font-size: 15px;
}
.launcher-item-desc {
  font-size: 14px;
}
.route-container {
  position: relative;
  z-index: 1;
  height: 100%;
  font-family: 'Avenir';
}

}
