html, body {
  height: auto;
  min-height: 100%;
  overflow-y: auto;
  font-family: 'Avenir';
}

.faq-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #1A2234; /* Your primary background color */
  background-size: cover;
  background-position: center;
  background-attachment: scroll;
  padding: 25px; /* Added padding */
  text-align: center;
  min-height: 100%;
  min-height: 100vh;
  overflow-y: auto;
}
.faq-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('./images/body/glow-top.svg');
  background-attachment: scroll;
  background-size: cover;
  opacity: 0.8;
  background-position: center;
  z-index: 0; /* Ensure the image is behind the content */
}



.faq-item {
  background: rgba(45, 45, 45, 0.13);
  border-radius: 10px;
  margin-bottom: 15px;
  overflow: hidden;
  transition: box-shadow 0.3s ease;
}

.faq-item:hover {
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.faq-question {
  background-color: transparent;
  color: rgb(188, 188, 188);
  font-family: 'Avenir';
  padding: 15px 25px;
  border: none;
  text-align: left;
  width: 100%;
  cursor: pointer;
  position: relative;
  font-size: 19px;
  transition: color 0.3s ease;
}

.faq-question:hover,
.faq-question:focus {
  color: white;
}

.faq-question::after {
  content: '>';
  font-size: 19px;
  position: absolute;
  right: 25px;
  transition: transform 0.3s ease;
}

.faq-question[aria-expanded="true"]::after {
  transform: rotate(90deg);
}

.faq-answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease, padding 0.3s ease;
  background-color: #f1f1f1;
  color: rgb(37, 37, 37);
  font-family: 'Avenir';
  border-left: 3px solid transparent;
}

.faq-answer p {
  padding: 5px;
  margin: 0;
  font-size: 17px;
  line-height: 1.7;
}

.faq-answer.open {
  max-height: 1000px; /* Adjust as needed for content */
  padding: 20px 25px;
}

.faq-content {
  align-items: center;
  position: relative;
  margin-top: 60px;
  margin-bottom: 50px;
  max-width: 90%;
  padding: 25px;
  text-align: left;
  
}
@media screen and (max-width: 399px) and (max-height: 798px) { 
  .faq-content {
    align-items: center;
    position: relative;
    margin-top: 30px;
    max-width: 95%;
    padding: 10px;
    text-align: left;
    
  }
}