
.header {
    display: flex;
    font-family: 'Avenir';
    justify-content: space-between;
    align-items: center;
    padding: 20px 30px;
    position: fixed;
    width: 100%;
    z-index: 1001;
    top: 0;
    left: 0;
    background-color: transparent;
}
.header-non-fixed {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 30px;
    width: 100%;
    z-index: 1001;
    top: 0;
    left: 0;
    background-color: transparent;
}

/* Make each column occupy equal width */
.menu-btn,
.logo-link,
.nav {
    flex: 1;
    display: flex;
    align-items: center; /* Ensure vertical alignment */
}
#logo-link {
    display: flex;
    align-items: center;
    text-decoration: none;
    margin: 0px;
    justify-content: center;
    transform: none;
    margin-left: 0px;
}
.menu-btn {
    background: none;
    border: none;
    justify-content: flex-start !important;  /* Align to the left */
    color: white;
    font-size: 38px;
    cursor: pointer;
    padding-left: 13%;
    /* Add more styles if necessary */
}
.menu-btn:hover {
    text-shadow: 0 0 8px #fdfaff41, 0 0 10px #f1e5ff44, 0 0 12px  #9f48fc43; /* Adjust as needed */
}
#menu {
    color: white;
    margin: 0px;
}

#universal-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(27, 27, 27, 0.9); /* Semi-transparent white */
    backdrop-filter: blur(10px); /* Blur effect for the background */
    opacity: 97%;
    z-index: 1000;
    text-align: center;
    display: flex;
    font-family: 'Avenir';
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: 0.5s;
}

#universal-menu a {
    display: block;
    margin: 25px auto;
    font-size: 35px;
    color: rgb(255, 255, 255);
    font-weight: 500;
}
#universal-menu a:hover {
    text-shadow: 0 0 8px #fdfaff41, 0 0 10px #f1e5ff44, 0 0 12px  #9f48fc43; /* Adjust as needed */
    transform: scale(1.05);         /* Enlarges the image slightly when hovered */
  }
#close-btn {
    position: absolute;
    left: 25px;
    top: 25px;
    font-size: 25;
    font-weight: 500;
    background: none;
    border: none;
    cursor: pointer;
    color: rgb(255, 255, 255);
}

.logo {
    max-height: 42px;
}

.nav {
    display: flex;
    justify-content: flex-end;
    padding-right: 13%;
}

.nav-link {
    color: white;
    text-decoration: none;
    font-size: 18px;
    margin-top: 10px;

}
.login-link {
    color: white;
    background-color: transparent;
    border: none;
    font-family: 'Avenir';
    padding: 9px 12px;
    margin-top: 0px;
    font-size: 18px;
}
.signup-link {
    color: white;
    font-family: 'Avenir';
    background-color: transparent;
    border: 1.5px solid #ffffff;
    padding: 9px 12px;
    margin-top: 0px;
    border-radius: 25px;
    font-size: 18px;
    cursor: pointer;
    margin-left: 20px;
}
.nav-link:hover {
    color: #ccc;
}
.login-link:hover {
    color: #b76cfa;
    text-shadow: 0 0 8px #fdfaff41, 0 0 10px #f1e5ff44, 0 0 12px  #9f48fc43; /* Adjust as needed */
}
.signup-link:hover {
    background-color: #a948f5;
    box-shadow: 0 0 8px #fdfaff41, 0 0 10px #f1e5ff44, 0 0 12px  #9f48fc43; /* Adjust as needed */
    color: rgb(255, 255, 255);
}
.account {
    display: none;
}
.socials {
    display: flex;  /* Arranges the social icons in a row */
    gap: 25px;  /* Adds space between the icons */
}

.social-icons {
    display: flex;
    justify-content: space-between; /* This will space out your icons equally across the parent div */
    align-items: center; /* This will vertically align your icons in the center */
    gap: 20px;
}

.social-icon img {
  width: 30px;
  height: auto;
  transition: 0.3s;              /* Smooth transition for hover effects */
}
.social-icon:hover img {
  opacity: 0.8;                  /* Reduces opacity to 70% when hovered */
  transform: scale(1.2);         /* Enlarges the image slightly when hovered */
}

.hr {
    display: none;
}
.profile-link {
    color: white;
    background-color: transparent;
    border: 1.5px solid #ffffff;
    padding: 9px 12px;
    margin-top: 0px;
    border-radius: 25px;
    font-size: 18px;
    font-family: 'Avenir';
    cursor: pointer;
}
.profile-link:hover {
    color: #d06bf8;
}

.My-info input {
    width: 100%;
    border: none;
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 10px;
  }
  .profilebuttons {
    display: flex;
    flex-direction: row;  /* Horizontal layout */
    justify-content: space-between;  /* Space out children equally */
    align-items: center;  /* Center children vertically */
    gap: 10px;  /* If you want gap between buttons (optional and might not be necessary with space-between) */
    width: 100%;  /* Take full width of parent (can adjust as needed) */
  }
  
  .My-info button {
    background: white;
    color: black;
    font-weight: 550;
    border-radius: 25px;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    margin-top: 10px;
    font-family: 'Avenir';
  }
  .My-info button:hover {
    box-shadow: 0 0 8px #fdfaff41, 0 0 10px #f1e5ff44, 0 0 12px  #9f48fc43; /* Adjust as needed */
    transform: scale(1.05);         /* Enlarges the image slightly when hovered */
  }
  .My-info p {
    color: rgb(255, 255, 255);
    border-radius: 25px;
    padding-top: 10px;
    padding-bottom: 10px;
    border: none;
    cursor: pointer;
    margin-bottom: 10px;
    margin-top: 10px;
  }

@media screen and (max-width: 700px) and (max-height: 1000px) { 
    .header {
      padding: 20px;
    }
  
    .logo {
      max-height: 40px;
    }
    .nav {
        display: none;
        padding-right: 0%;
    }
    .menu-btn {
        padding-left: 0%;
        /* Add more styles if necessary */
    }
  
    .nav-link,
    .login-link,
    .signup-link {
      margin-left: 10px;
    }
    
    #universal-menu a {
        font-size: 26px;
        margin: 23px auto;
    }
    
    .signup-link {
      padding: 7px 10px;
    }
    .account {
        margin-top: 0px;
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .login-link-menu {
        color: white;
        font-family: 'Avenir';
        margin: 20px auto;
        font-weight: 500;
        background-color: transparent;
        border: none;
        cursor: pointer;
        font-size: 24px;
    }
    .signup-link-menu {
        background: linear-gradient(to right, #a05ff4, rgb(131, 4, 190));
        color: white;
        font-family: 'Avenir';
        margin: 15px auto;
        font-weight: 500;
        padding: 10px 18px;
        border-radius: 30px;
        background-color: transparent;
        border: none;
        cursor: pointer;
        font-size: 24px;
    }
    .signup-link-menu:hover {
        background-color: #a948ff;
        box-shadow: 0 0 8px #fdfaff41, 0 0 10px #f1e5ff44, 0 0 12px  #9f48fc43; /* Adjust as needed */
        color: rgb(255, 255, 255);
        transform: scale(1.05);         /* Enlarges the image slightly when hovered */
    }
    .login-link-menu:hover {
        color: #b76cfa;
        text-shadow: 0 0 8px #fdfaff41, 0 0 10px #f1e5ff44, 0 0 12px  #9f48fc43; /* Adjust as needed */
    }
    
    .profile-link {
        color: white;
        background-color: transparent;
        margin: 15px auto;
        font-weight: 400;
        padding: 10px 18px;
        border-radius: 30px;
        border: 1.5px solid #ffffff;
        background-color: transparent;
        cursor: pointer;
        font-size: 23px;
        font-family: 'Avenir';
    }
    .profile-link:hover {
        color: #b76cfa;
    }
    .social-icon img {
        width: 35px;
        height: auto;
        transition: 0.3s;              /* Smooth transition for hover effects */
      }
  }
  
  
  
  
  
