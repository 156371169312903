/* UpgradePremium.css */

.upgrade {
    display: flex;
    background-color: #28385a; /* Your primary background color */
    background-size: cover;
    background-position: center;
    background-attachment: scroll;
    align-items: center;
    justify-content: center;
    height: 100vh;
    flex-direction: column;
    max-height: 100%;
    width: 100%;
    font-family: 'Avenir';
    padding: 20px;
    z-index: 1;
}
.upgrade h2 {
    color: #fff;
    font-size: 2.2rem;
    display: flex;
    margin-bottom: 30px;
    align-items: center;
}

.upgrade p {
    font-size: 1.2rem;
    text-align: center;
    max-width: 80%;
    margin-bottom: 30px;
    color: #c1c1c1;
}

.upgrade button {
    display: inline-block;
    background: linear-gradient(45deg, #af54ff, #922cff);
    border: none;
    margin-top: 20px;
    padding: 12px 20px;
    color: #fff;
    font-weight: bold;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.2s ease, transform 0.2s ease;
    box-shadow: 0 0 8px #fdfaff41, 0 0 10px #f1e5ff44, 0 0 12px  #9f48fc43; /* Adjust as needed */
    border-radius: 25px;
    outline: none;
}

.upgrade button:hover {
    background: #04cb8f;  
    transform: scale(1.1);
}

@media screen and (max-width: 500px) and (max-height: 798px) { 
    .upgrade h2 {
        color: #fff;
        font-size: 1.6rem;
        display: flex;
        margin-bottom: 30px;
        align-items: center;
    }
    .upgrade {
        overflow-y: hidden;
    }
}
