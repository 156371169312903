.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  }
  
  .modal-content {
    background-color: rgba(27, 27, 27, 0.3); /* Semi-transparent white */
    backdrop-filter: blur(10px); /* Blur effect for the background */
    padding: 40px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    position: relative;
    width: auto;
    height: auto;
    border-radius: 30px;
  }
  
  .close-modal-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    background: none;
    border: none;
    font-size: 1.2rem;
    cursor: pointer;
    color: #F5F5F5;
  }
  
  .enlarged-image {
    width: 512px !important;
    height: 512px;
    border-radius: 20px;
  }
@media screen and (max-width: 500px) and (max-height: 798px) { 
  .modal-overlay {
    display: none;
    }
}
  