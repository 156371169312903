.edit-profile-container {
  display: flex;
  font-family: 'Avenir';
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
  background-color: #1A2234; /* Your primary background color */
  background-size: cover; /* Ensure the background covers the entire viewport */
  color: #ffffff; /* Setting all texts to white */
}

.edit-profile-form {
  display: flex;
  font-family: 'Avenir';
  flex-direction: column;
  max-width: 400px;
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  gap: 20px; /* Adds space between child elements */
}

.edit-profile-title {
  align-self: flex-start; /* Aligns the title to the left */
  font-size: 24px;
  margin-bottom: 20px;
}

.edit-profile-box {
  display: flex;
  width: 80%;
  margin-bottom: 25px;
  flex-direction: column;
  padding: 20px;
  font-family: 'Avenir';
  background-color: rgba(0, 0, 0, 0.3); /* A slight background to make text more readable */
  border: none;
  border-radius: 25px;
}

.edit-profile-form input {
  width: 100%;
  border: none;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 10px;
  font-family: 'Avenir';
}
.edit-profile-form select {
  width: 100%;
  border: none;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 10px;
}

.edit-profile-form button {
  background: white;
  color: black;
  font-weight: 550;
  border-radius: 20px;
  padding: 10px 20px;
  border: none;
  font-family: 'Avenir';
  cursor: pointer;
  margin-bottom: 0px;
}
.edit-profile-form button:hover {
  box-shadow: 0 0 8px #fdfaff41, 0 0 10px #f1e5ff44, 0 0 12px  #9f48fc43; /* Adjust as needed */
  transform: scale(1.05);         /* Enlarges the image slightly when hovered */
}

.delete-modal {
  background: white;
  color: #432a4d;
  font-weight: 500;
  border-radius: 20px;
  padding: 40px;
  max-width: 90%;
  gap: 20px;
  width: 600px;
  position: relative;
  display: flex;
  font-family: 'Avenir';
  flex-direction: column;
  justify-content: space-between;
}
.delete-modal a {
  color: #d06bf8;
  cursor: pointer;
}

.edit-profile-message {
  margin-top: 20px;
  font-weight: bold;
}

@media screen and (max-width: 399px) and (max-height: 798px) { 
  .modal {
    max-height: 85vh;
}
.delete-modal {
  font-size: 15px;
}

}
