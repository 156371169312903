.hero-body {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    height: 100%;
    background-color: #1A2234; /* Your primary background color */
  }
  
  .hero-body::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('./images/body/glow-top.svg');
    background-size: cover;
    opacity: 0.55;
    background-position: center;
    z-index: 0; /* Ensure the image is behind the content */
  }