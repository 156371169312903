
html, body {
  min-height: 100%;
  overflow-y: scroll;
  font-family: 'Avenir';
}
.particles-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none; /* Allows click events to pass through */
  z-index: 0;
}

.Homepage {
  display: flex;
  position: relative;
  background-color: #1A2234; /* Your primary background color */
  font-family: 'Avenir';
  background-size: cover;
  background-position: center;
  background-attachment: scroll;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 100%;
  width: 100%;
  overflow-y: auto;
}
.Homepage::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('./images/body/glow-bottom.svg');
  background-attachment: scroll;
  background-size: cover;
  opacity: 0.85;
  background-position: center;
  z-index: 0; /* Ensure the image is behind the content */
}
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: 'Avenir';
}
#particles-js {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.main {
  display: flex;
  justify-content: center;
  align-items: center;
}


.highlight {
color: white;
/* Add any other desired styles */
/* Glowing effect */
animation: glowing 4s ease-in-out infinite;
text-shadow: 0 0 1px rgba(255, 255, 255, 0.3), 0 0 2px rgba(255, 255, 255, 0.5), 0 0 3px rgba(255, 255, 255, 0.3), 0 0 4px rgba(180, 180, 180, 0.5), 0 0 6px rgba(180, 180, 180, 0.3), 0 0 8px rgba(180, 180, 180, 0.5), 0 0 10px rgba(180, 180, 180, 0.3), 0 0 12px rgba(180, 180, 180, 0.5);
}
.hero{
  display: flex;
  padding-top: 3%;
  padding-bottom: 3%;
  padding-left: 10%;
  padding-right: 10%;
  flex-direction: row;
  background-color: transparent;
  margin-bottom: 10px;
  color: #ffffff;
  z-index: 1; /* Ensure the image is behind the content */
  justify-content: space-between;
  gap: 150px;
}
.overlay-image {
 width: 50%;
}
.openingvideo {
  padding: 10px;
  border-radius: 10%;
}
.openingvideo video{
  border-radius: 10px;
}
.image-slider {
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-slider img{
  border-radius: 10px;
}
.image-slider:hover::before {
  top: 0;
}

.image-slider:hover::after {
  bottom: 0;
}

.emotion-1u5k6oi {
  /* Style your buttons */
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  /* Add more styles as needed */
}

.emotion-1u5k6oi:first-of-type {
  left: 10px; /* Adjust as needed */
}

.emotion-1u5k6oi:last-of-type {
  right: 10px; /* Adjust as needed */
}

.slider-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  cursor: pointer;
  z-index: 2; /* to ensure they are above the image */
}

.slider-button.prev {
  left: 10px;
}

.slider-button.next {
  right: 10px;
}
.image-slider img {
  max-width: 512px;
  max-height: 512px;
  object-fit: cover; /* Ensures the image covers the specified dimensions without distortion */
  border-radius: 10px; /* Optional: maintains rounded corners */
}


.hero-text {
z-index: 1;
flex: 1; /* Takes up half the space in flex container */
font-family: Avenir;
display: flex;
font-weight: 500;
flex-direction: column;
text-align: left;
color: white;
height: 80vh;
}
.title {
font-size: 60px;
}
.gradient-text {
z-index: 1;
background: linear-gradient(45deg, #00c77b, #ff00f7);
background-clip: text; /* standard property */
-webkit-background-clip: text; /* webkit-specific property */
-webkit-text-fill-color: transparent;
font-size: 33px;
margin-top: 50px;
text-align: left;
font-weight: bold;
}
.description-text {
display: flex;
z-index: 1;
flex-direction: column;
text-align: left;
color: white;
margin-top: 30px;
margin-bottom: 0px;
line-height: 2; /* Add this line */
}
.description-text h4{
font-size: 18px;
font-weight: 400;
}

.buttons {
  display: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.Dashboard-button{
z-index: 1;
}
.cta-button {
  display: inline-block;
  background: linear-gradient(45deg, #af54ff, #922cff);
  color: rgb(255, 255, 255);
  outline: none;
  border: none;
  font-family: 'Avenir', sans-serif;
  text-decoration: none;
  padding: 12px 12px;
  font-size: 19px;
  font-weight: 550;
  width: 12rem;
  border-radius: 25px;
  transition: all 0.4s ease;
  margin-top: 50px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.cta-button:before {
  content: '';
  position: relative;
  top: 0;
  left: -100%;
  width: 300%;
  height: 100%;
  transition: all 0.4s ease;
  border-radius: 25px;
  z-index: 0;
}

.cta-button:hover:before {
  left: 0;
}

.cta-button:hover {
background: linear-gradient(45deg, #a23afd, #7f26de);
color: white;
box-shadow: 0 0 8px #fdfaff82, 0 0 10px #f1e5ff93, 0 0 12px  #9f48fc98; /* Adjust as needed */
transform: scale(1.15);         /* Enlarges the image slightly when hovered */
}

.design-kit-button {
  display: inline-block;
  background: linear-gradient(45deg, #af54ff, #922cff);
  color: rgb(255, 255, 255);
  outline: none;
  border: none;
  font-family: 'Avenir', sans-serif;
  text-decoration: none;
  padding: 12px 12px;
  font-size: 19px;
  font-weight: 550;
  width: 10rem;
  border-radius: 25px;
  transition: all 0.4s ease;
  margin-top: 50px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.design-kit-button:before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 300%;
    height: 100%;
    transition: all 0.4s ease;
    border-radius: 25px;
    z-index: 0;
}

.design-kit-button:hover:before {
    left: 0;
}

.design-kit-button:hover {
  background: linear-gradient(45deg, #a23afd, #7f26de);
  color: white;
  box-shadow: 0 0 8px #fdfaff82, 0 0 10px #f1e5ff93, 0 0 12px  #9f48fc98; /* Adjust as needed */
  transform: scale(1.15);         /* Enlarges the image slightly when hovered */
}

.fade-in {
opacity: 1;
transition: opacity 0.5s ease;
}

.fade-out {
  opacity: 0;
  transition: opacity 0.5s ease;
}


.footer {
  padding: 20px 30px; 
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 99%; 
  margin-bottom: 10px;
  border-radius: 45px;
  background-color: #2d2d2d21;
  z-index: 1;
}

.footer-sentence {
  color: #efefef;
  align-items: center;
  font-size: 13px;
  margin-left: 100px;
  text-align: center;
}
.logo-footer {
  display: block;  /* Ensuring the logo is treated as a block-level element */
  width: 120px;
  padding: 5px;  /* Making sure the logo scales if necessary */
}

.socials {
  display: flex;  /* Arranges the social icons in a row */
  gap: 25px;  /* Adds space between the icons */
}

.social-icon {
  display: inline-block;  /* Ensures each link is treated as a block-level within the flex container */
  background-color: transparent;
}
.social-icon img {
width: 30px;
height: auto;
transition: 0.3s;              /* Smooth transition for hover effects */
}
.social-icon:hover img {
opacity: 0.8;                  /* Reduces opacity to 70% when hovered */
transform: scale(1.2);         /* Enlarges the image slightly when hovered */
}

@media screen and (max-width: 700px) and (max-height: 1000px) { 
  
  .homepage{
    align-items: center;
    justify-content: center;
  }

  .hero{
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 3%;
    padding-bottom: 80px;
    padding-left: 1%;
    padding-right: 1%;
    gap: 5px;
    overflow-x: hidden; /* Prevents horizontal scrolling */
  }

  .highlight {
    font-size: 18px;
  }

  .gradient-text {
    font-size: 29px;
    background: linear-gradient(45deg, #18df96, #fb5cf6);
    text-shadow: #343333;
    background-clip: text; /* standard property */
    -webkit-background-clip: text; /* webkit-specific property */
    -webkit-text-fill-color: transparent;
  }
  .socials {
    display: flex;  /* Arranges the social icons in a row */
    gap: 0px !important;  /* Adds space between the icons */
  }
  .social-icon {
    width: 40px;
   }
  .description-text {
    margin-top: 30px;
    margin-bottom: 30px;
    align-items: center;
    justify-content: center;
    text-align: center;
    line-height: 1.6;
  }

  .cta-button {
    position: relative;
    font-size: 18px;
    z-index: 1;
    margin-top: 30px;
    text-align: center;
  }
  .hero-text {
    display: flex;
    height: auto;
    margin-right: 0px;
    padding-right: 20px;
    padding-left: 20px;
    margin-top: 15px;
    font-weight: 500;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: white;
    max-width: 95%;
  }
  .title{
    font-size: 50px;
  }
      /* Add this to HomePage.css */
  .hero-text h1, .hero-text p, .cta-button {
    transition: opacity 0.5s ease;
  }
  .gradient-text {
    font-size: 27px;
    margin-top: 20px;
    margin-bottom: 15px;
  }
  .footer {
    margin-top: 5px;
    width: 95%;
    padding: 10px;
    height: 12vh; 
  }
  .footer {
    position: static;
  }
  
  .footer-sentence {
    font-size: 12px;
    margin-left: 10px;
    text-align: start;
 }
  .logo-footer {
    display: none;

  }
  .animated-text {
    overflow: hidden;
    font-size: 31px;
  }  
  .animated-text-container {
    padding: 5px;
    position: relative;
    overflow: hidden; 
    height: 120px;
    margin-bottom: 0px;
  }
  .fade-in {
    opacity: 1;
    transition: opacity 0.5s ease;
}

  .fade-out {
      opacity: 0;
      transition: opacity 0.5s ease;
  }
  .design-kit-button {
    margin-top: 25px;
    text-align: center;
    justify-content: center;
  }
  .openingvideo{
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: auto;
    margin-bottom: 10%;
  }
  .description-text h4{
    font-size: 16px;
    font-weight: 400;
    max-width: 98%;
    }
    #particles-js {
      display: none;
    }
    .image-slider {
      text-align: center;
      max-width: 100%;
      height: auto;
      margin-bottom: 10%;
      padding: 0; /* Removes extra padding */
      object-fit: cover; /* Adjusts how the image is scaled and cropped */
    }
    .image-slider img {
      width: 100%; /* Ensures the image scales to fit its container */
      height: auto; /* Maintains the aspect ratio */
      object-fit: cover; /* Scales the image without distortion */
      border-radius: 10px; /* Optional: keeps the rounded corners */
      display: block; /* Avoids inline spacing issues */
      padding: 0; /* Removes extra padding */
      margin-bottom: 10%;

  }
    .slider-button.next svg {
      transform: scaleX(-1);
  }  
}
