.loading-container {
    display: flex;
    background-color: #1A2234; /* Your primary background color */
    background-size: cover;
    background-position: center;
    background-attachment: scroll;
    align-items: center;
    justify-content: center;
    height: 100vh;
    flex-direction: column;
    max-height: 100%;
    width: 100%;
    font-family: 'Avenir';
    padding: 20px;
}
.loading-container::before {
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('./images/body/glow-top.svg');
    background-attachment: scroll;
    background-size: cover;
    opacity: 1;
    background-position: center;
    position: absolute;
    z-index: 0;
  }
.spinner {
    border: 16px solid #f3f3f3;
    border-top: 16px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}