
.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.Signup-modal {
  background: linear-gradient(135deg, #8936cd, #5a2494);
  color: white;
  font-weight: 500;
  border-radius: 20px;
  padding: 40px;
  width: 400px;
  max-width: 90%;
  position: relative;
  display: flex;
  font-family: 'Avenir';
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.Signup-modal h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 25px;
}
.Signup-modal h8 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 40px;
}

.signup-form input, .signup-form select {
  width: 100%;
  border: none;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 10px;
}

.signup-form button[type="submit"] {
  background: linear-gradient(45deg, #ffffff, #f3f3f3);
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(19, 19, 19);
  margin: 0 auto;           /* To center the button horizontally */
  font-weight: 550;
  border-radius: 35px;
  padding: 10px 15px;
  border: none;
  font-family: 'Avenir';
  cursor: pointer;
}
.signup-form button:hover {
  box-shadow: 0 0 8px #fdfaff41, 0 0 10px #f1e5ff44, 0 0 12px  #9f48fc43; /* Adjust as needed */
  transform: scale(1.05);         /* Enlarges the image slightly when hovered */
}
.signup-buttons{
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-direction: column;
  align-items: center; /* Center horizontally */
}
.signup-option{
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(0, 0, 0);
  margin: 0 auto;           /* To center the button horizontally */
  font-weight: 550;
  border-radius: 35px;
  padding: 10px 20px;
  border: none;
  font-family: 'Avenir';
  cursor: pointer;
}
.signup-option:hover {
  box-shadow: 0 0 8px #fdfaff41, 0 0 10px #f1e5ff44, 0 0 12px  #9f48fc43; /* Adjust as needed */
  transform: scale(1.05);         /* Enlarges the image slightly when hovered */
}
.registered-link-signup {
  color: #ffffff; /* Change the color as per your requirement */
  text-decoration: none;
  margin-bottom: 25px;
  padding: 5px;
  font-size: 14px;
  display: inline-block;
  cursor: pointer;
}

.registered-link:hover {
  color: #fff427; /* Change the color as per your requirement */
}

.signup-form .google-signup {
  display: flex;
  margin: 0 auto;           /* To center the button horizontally */
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: black;
  border-radius: 20px;
  margin-top: 20px; 
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}
.close-button {
  background: transparent;
  color: lightgray;
  border: none;
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}
.back-button {
  background: transparent;
  color: lightgray;
  border: none;
  position: absolute;
  left: 20px;
  top: 20px;
  cursor: pointer;
}
.go-back-icon{
  width: 13px;
}
@media (max-width: 390px) {
  .Signup-modal {
    max-height: 80vh;
  }
  .Signup-modal h2 {
    margin-top: 30px;
    margin-bottom: 20px;
  }
  .google-icon {
    display: none;
  }
  .signup-form button[type="submit"] {
    margin-top: 25px;           /* To center the button horizontally */
  }

}