
body {
  background-color: white;
  max-height: 100vh;
  background-attachment: fixed;
  margin: 0 !important;
  padding: 0 !important;
  font-family: 'Avenir';
  overflow: hidden;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.box-container {
  display: flex;
  max-height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: hidden;
  margin: 0 !important;
  padding: 0 !important;
}

#side-bar {
  flex: 0 0 30vh;  /* Sets the sidebar's width to a fixed value */
  background-color: #1d1d1d;
  padding: 20px;
  overflow-y: auto;
  max-height: 100vh;
  transition: transform 0.3s ease-out;
}
#side-bar.active {
  transform: translateX(0);
}
.sidebar-icon {
    width: 25px;
    margin-left: 5px;
    height: 25px;
    position: absolute;
    border-radius: 5px;
    top: 15px;
    left: 15px;
    z-index: 100;
    background: url('./images/dashboard/menu-Close-icon.png') center/contain no-repeat; 
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
}
.sidebar-icon img {
  width: 20px;
  object-fit: contain;
}

#side-bar ul {
  list-style-type: none;
  margin-top: 45px;
  transition: background-color 0.3s ease, color 0.3s ease;  /* Transitions both background-color and color */
}

#side-bar ul li {
    padding: 25px 15px;
    display: flex;
    align-items: center;
}

#side-bar ul li a {
  color: rgb(255, 255, 255);
  text-decoration: none;
  font-weight: 500;
}
#side-bar ul li p {
  padding-right: 5px;
  font-size: 20px;
}
.sidebarlinks {
  display: inline-block; /* or inline */
  margin-right: 5px; /* Adjust spacing as needed */
}
.active-link {
  background-color: #4848487f;  /* light gray */
  border-radius: 20px;
}
.active-link:hover {
  box-shadow: 0 0 8px #fdfaff41, 0 0 10px #f1e5ff44, 0 0 12px  #9f48fc43; /* Adjust as needed */
  transform: scale(1.05);         /* Enlarges the image slightly when hovered */
}
.right-side {
  flex-grow: 1;  /* Allows the right side to take up the remaining space */
  display: flex;
  justify-content: flex-start; 
  background-color: #fafafa; 
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  max-height: 100vh;
  overflow: auto;  /* Prevents the right side from expanding beyond its container */
}
.icon {
  margin-right: 15px;
  width: 20px;
  height: 20px;
  vertical-align: sub;
  padding-top: 0px;
}

.prompt-container {
  display: flex;        /* New property */
  flex-direction: column-reverse; /* New property */
  background-size: cover;
  width: 100%;
  max-height: 100vh;
  justify-items: center;
  background-color: #fafafa;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  position: relative;
  margin-bottom: 0;
  overflow: auto;
}
.prompt-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('./images/background/tekt.png');
  background-size: cover;
  opacity: 0.8;
  background-position: center;
  z-index: 0; /* Ensure the image is behind the content */
}
/* Style for when the sidebar is open */
.tekt-image-sidebar-open {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1; /* Ensure the image stays behind other content */
}

/* Style for when the sidebar is closed */
.tekt-image-sidebar-closed {
  position: fixed; /* Fixed positioning to cover the entire viewport */
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  z-index: -1; /* Ensure the image stays behind other content */
}

.content-wrapper {
  position: relative;
  bottom: 0;
  width: 100%;
  align-items: flex-end;
  display: flex;
  flex-direction: column; /* Switch back to column so the order remains the same */
  height: calc(var(--vh, 1vh) * 100)
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
  color: #000;
  margin-left: 5px;
}

textarea {
    overflow-y: auto;
    resize: none;
}

.input-group {
    display: flex;
    margin-top: 0;
    width: 100%;
    align-items: flex-start; /* Change to flex-start */
    justify-content: center; /* space out items evenly */
    position: relative;
    overflow-y: auto;
    padding-bottom: 0px;
    flex-shrink: 0;
}
.input-group-interior {
  display: flex;
  margin-top: 0;
  width: 100%;
  align-items: flex-start; /* Change to flex-start */
  justify-content: center; /* space out items evenly */
  position: relative;
  overflow-y: auto;
  padding-bottom: 0px;
  flex-shrink: 0;
}

.prompt-input {
  font-family: 'Avenir';
  font-size: 16px;
  color: #000000;
  margin-top: 20px;
  line-height: 16px;
  text-align: left;
  height: auto;
  flex-grow: 1;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Adjust values as needed */
  border-radius: 20px !important;
  padding-left: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
  border: 1px solid #cccccc;
  resize: none;
  overflow-y: auto !important;
  max-height: 180px !important;
  line-height: 1.3;
  padding-right: 145px;
}
.prompt-input::placeholder {
    color: #888;        /* Change color */
    font-size: 16px;
}
.disclaimer {
  font-size: 12px;
  color: #777;
  margin-top: 20px;
  margin-bottom: 5px;
  width: 100%;
  text-align: center;
}
.disclaimer-interior{
  font-size: 12px;
  color: #777;
  margin-top: 20px;
  margin-bottom: 5px;
  width: 100%;
  text-align: center;
}

.cta-button7 {
  color: white;
  text-decoration: none;
  padding: 5px 10px;
  border-radius: 25px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
}

.user-message, .bot-message {
  display: flex;
  max-width: 75%; /* Adjust the width to your preference */
  margin-right: 0px;
  margin-left: 0px;
  border-radius: 25px;
  border: 0.3px solid rgba(210, 210, 210, 0.385); /* This line adds the border */
  line-height: 1.5;
  padding-right: 15px;
  padding-left: 15px;
  padding-bottom: 10px;
  padding-top: 10px;
  font-size: 17px;

}

.user-message {
  float: right;
  clear: both;
  background-color: #8859fe;
  color: white;
  margin-right: 0px;
  padding-left:20px;
  padding-right:20px;
  padding-bottom: 20px;
  padding-top: 20px;
}
.audio-player{
  display: flex;
  border: none;
  width: 85%;
  height: 30px;
}
.bot-response-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;  /* create some space between this container and the speaker button */
}

.bot-response-player {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  margin-top: 10px;
}
.bot-response-player button {
  display: block;
  border: none;
  background-color: transparent;
  height: 25px;
  width: 25px;
}
.speaker-icon {
  display: flex;
  background-color: transparent;
  cursor: pointer;
  font-size: 17px;
}
.speaker-icon:hover {
  transform: scale(1.3);
  transition: transform 0.3s ease;
}
.bot-message {
  float: left;
  clear: both;
  display: flex;
  flex-direction: column;
  background-color: #fdfdfd;
  color: black;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 20px;
  overflow-x: hidden;
}
.modal-content {
  width: 512px;
  height: 512px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.cta-button7:hover {
  transform: scale(1.1);
  transition: transform 0.3s ease;
}
.faq-icon:hover {
  transform: scale(1.1);
  transition: transform 0.3s ease;
}
.suggestion-questions p:hover {
  transform: scale(1.03);
  transition: transform 0.3s ease;
}
.suggestion-prompts p:hover {
  transform: scale(1.03);
  transition: transform 0.3s ease;
}
.image-preview-container {
  width: 30px;
  height: 30px;
}
.image-preview {
  width: 30px;
  height: 30px;
}
.chat-image {
  border-radius: 20px;
}
/* Define keyframes for the dot animation */
@keyframes dotPulse {
  0%   { opacity: 0.5; }
  50%  { opacity: 1; }
  100% { opacity: 0.5; }
}
.typing-animation {
  display: flex;
  position: relative;
  height: 5px;
  padding-right:8px;
  max-width: 5px;
  margin-left: 11px;
  margin-top: 8px;
  margin-right: 25px;
}

.typing-animation:before,
.typing-animation:after,
.typing-animation span {
  position: absolute;
  top: 0;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #333;  /* you can adjust the color */
  content: '';
  animation: dotPulse 1s infinite;
}

.typing-animation span { left: 8px; }

.typing-animation:before {
  left: -10px;
  animation-delay: -0.25s;  /* delay for the first dot */
}

.typing-animation:after {
  left: 26px;
  animation-delay: 0.25s;  /* delay for the last dot */
}

.suggestion-questions-container{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.suggestion-questions {
  display: flex;
  width: 100%;
  gap: 10px;
  justify-content: center;
  align-items: center; 
}

.suggestion-questions button {
  display: inline-block;
  padding: 8px 10px;
  background-color: rgb(182, 182, 182);
  color: rgb(255, 255, 255);
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 10px;
  transition: background 0.3s ease;
}

.suggestion-questions button:hover {
  background: #cc0000; /* darker red on hover */
}
.suggestion-prompts-container{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.suggestion-prompts {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center; 
  gap: 10px;
}
.suggestion-prompts p {
  cursor: pointer;
  color: #6d6d6d;
  border: 0.3px solid rgba(210, 210, 210, 0.385); /* This line adds the border */
  font-size: 14px;
  border-radius: 25px;
  background: linear-gradient(to bottom, #ffffffd8, rgba(234, 234, 234, 0.753));  padding-left:13px;
  font-size: 14px;
  box-shadow: 0 2px 10px rgba(85, 85, 85, 0.069);
  padding-right:13px;
  padding-bottom: 15px;
  padding-top: 15px;
}
.suggestion-questions p {
  cursor: pointer;
  border: 0.3px solid rgba(210, 210, 210, 0.385); /* This line adds the border */
  font-size: 14px;
  color: #6d6d6d;
  border-radius: 25px;
  background: linear-gradient(to bottom, #ffffffd8, rgba(234, 234, 234, 0.753));  padding-left:13px;
  box-shadow: 0 2px 10px rgba(85, 85, 85, 0.069);  
  padding-left:13px;
  padding-right:13px;
  padding-bottom: 15px;
  padding-top: 15px;
}
.imagename {
  cursor: pointer;
  border: 0.3px solid rgba(210, 210, 210, 0.385); /* This line adds the border */
  font-size: 14px;
  color: #6d6d6d;
  border-radius: 25px;
  background: linear-gradient(to bottom, #ffffffd8, rgba(234, 234, 234, 0.753));  padding-left:13px;
  box-shadow: 0 2px 10px rgba(85, 85, 85, 0.069);  
  padding-left:13px;
  padding-right:13px;
  padding-bottom: 15px;
  padding-top: 15px;
}
.imagename:hover {
  transform: scale(1.03);
  transition: transform 0.3s ease;
}

.bot-icon {
  font-size: 17px;
  margin-right: 5px;
}

.send-button-container {
  display: flex;
  align-items: center;
  position: absolute;
  right: 1vw;
  bottom: 3px;
}

.faq-icon {
  width: 36px;
  margin-top: 6px; 
  margin-right: 10px; 
  cursor: pointer;
}

#chat-box {
  width: 100%;
  height: auto;
  max-height: 73vh;
  border: none;
  border-radius: 20px;
  padding: 10px;
  flex-grow: 1; /* Makes this div take all available space */
  overflow-y: auto;
  margin-bottom: 20px;
  margin-top: 20px !important;
  padding-top: 30px;
}
#chat-box::-webkit-scrollbar {
  width: 3px;
}
#chat-box img {
  max-width: 350px;
}
#chat-box::-webkit-scrollbar-thumb {
  background-color: #ddd;
  outline: none;
}

#chat-box::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}
.bot-image {
  max-width: 100%;
  display: flex;
  float: left;
  clear: both;
  background-color: #f3f3f3;
  color: black;
  overflow-x: hidden;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-right: 0px;
  margin-left: 0px;
  border-radius: 25px;
}
.bot-image img{
  border-radius: 15px;
}
.unique-form {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center; /* Center align the items */
}
.first-column{
  padding: 5px;
  gap: 5px;
}
.second-column{
  padding: 5px;
  gap: 5px;
}
.custom-camera {
  display: none;
}

.unique-text-input,
.unique-number-input {
  border: 1px solid #ccc;
  padding: 25px;
  border-radius: 25px;
  width: 100%;
  outline: none;
  transition: border-color 0.3s ease;
}

.unique-file-input {
  display: none;
}

.unique-text-input:focus,
.unique-number-input:focus {
  border-color: #4e1d74;
  border-radius: 20px !important;
}

.unique-submit-button {
  white-space: nowrap;
  width: 100%;
  font-family: 'Avenir';
  justify-content: center;
  align-items: center;
  display: inline-block;
  background: linear-gradient(90.13deg, #4e1d74 0.1%, #65238A 98.06%); /* Gradient background from reference */
  color: #F5F5F5; /* Color from reference */
  text-decoration: none;
  padding: 15px;
  font-size: 16px; /* Font size from reference */
  border-radius: 8px; /* Border radius from reference */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  font-weight: 600; /* Font weight from reference */
  cursor: pointer;
  transition: all 0.3s ease; /* Transition from reference */
}

.unique-submit-button:hover {
  background-color: #9b28d9;
  transform: scale(1.05);
  /* You can also add hover styles from reference if required */
}
.unique-select-input {
  border-radius: 25px;
  border: 1px solid #ccc;
  padding: 15px;
  margin: 5px;

}
.custom-file-upload {
  display: inline-block;
  padding: 15px;
  cursor: pointer;
  font-size: 14px;
  margin: 5px;
  border-radius: 25px;
  font-weight: 400;
  text-align: center;
  background-color: #9b28d9;
  color: white;
}
.custom-file-upload:hover {
  background-color: #9b28d9;
  transform: scale(1.05);   
}
/* Modal Styles */
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1000;
}

.modal-root {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1001;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  /* Add more styling as needed */
}


.crop-modal-section {
  padding: 20px;
  border-bottom: 1px solid #e5e5e5;
}

.crop-modal-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.body-small {
  font-size: 14px;
}

.crop-modal-image {
  display: flex;
  flex-direction: column;
  position: relative;
}

.cropper {
  position: relative;
  width: 100%;
  height: 300px;
  margin: 0 auto;
  border: 1px solid #e5e5e5;
  overflow: hidden;
}

/* Button Styles */
.btn {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn-primary {
  background-color: #007BFF;
  color: #fff;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.btn-secondary {
  background-color: #6c757d;
  color: #fff;
  margin-top: 10px;
}

.btn-secondary:hover {
  background-color: #545b62;
}

.btn-neutral {
  background-color: transparent;
  color: #333;
}

.btn-neutral:hover {
  background-color: #f5f5f5;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.cropper {
  position: relative;
}

.cropper img {
  width: 100%;
  display: block;
}

.cropper-overlay {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
}

.cropper-overlay.top,
.cropper-overlay.bottom {
  width: 100%;
}

.cropper-overlay.left,
.cropper-overlay.right {
  top: 0;
}

.cropper-square {
  position: absolute;
  border: 2px solid white;
}

.cropper-handle {
  position: absolute;
  background-color: white;
}

.cropper-handle.top-left {
  top: -6px;  /* half of the width/height */
  left: -6px;
}

.cropper-handle.bottom-right {
  bottom: -6px;
  right: -6px;
}

.ReactCrop {
  position: relative;
}


/* Style the cropping area */
.ReactCrop .ReactCrop__crop-selection {
  border: none !important;
  background-color: transparent;
  
}
.custom-upload-btn{
    color: white;
    text-decoration: none;
    padding: 5px 10px;
    border-radius: 25px;
    appearance: none;
    margin-left: 5px;
    border: none;
    cursor: pointer;
}
.custom-upload-btn:hover {
  transform: scale(1.1);
  transition: transform 0.3s ease;
}


.before-after-container {
  position: relative;
  width: 100%;
  max-width: 600px; /* Adjust max width as needed */
  height: 400px; /* Adjust height as needed */
  margin: 20px auto;
  overflow: hidden;
  cursor: ew-resize;
  display: flex;
}

.before-image,
.after-image {
  position: absolute;
  top: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  height: 100%;
}

.before-image {
  left: 0;
  z-index: 1;
  transition: width 0.1s ease-out;
}

.after-image {
  right: 0;
  z-index: 0;
  transition: width 0.1s ease-out;
}

.slider-handle {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 5px;
  background-color: white;
  z-index: 2;
  cursor: ew-resize;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 700px) and (max-height: 1000px) {  
  

  .modal-root {
    width: 90%;
  }
  
  #side-bar ul {
    list-style-type: none;
    margin-top: 60px;
    transition: background-color 0.3s ease, color 0.3s ease;  /* Transitions both background-color and color */
  }

  #side-bar[style*="block"] { /* Check if the style attribute contains 'block' */
      transform: translateX(0);
  }
  .hide-on-mobile {
    display: none;
  }
  .hide {
    display: none;
  }

  .bot-message {
    float: left;
    max-width: 75%;
    display: flex;
    clear: both;
    margin-top: 20px;
    margin-bottom: 20px;
    padding-left:15px;
    padding-right:15px;
    padding-bottom: 20px;
    padding-top: 20px;
  }
  .suggestion-questions p{
    font-size: 13px;
    box-shadow: none;
  }
  .suggestion-prompts p{
    box-shadow: none;
    font-size: 13px;

  }

  .suggestion-questions-container {
    display: flex;
    justify-content: center;  /* This will center items horizontally */
    align-items: center; 
  }
  .bot-image {
    max-width: 85%;
    display: inline-block;
    flex-direction: column; /* Stack children vertically */
    align-items: start; 
    margin-top: 15px;
    padding: 15px;
  }
  .bot-image-interior {
    max-width: 100%;
    display: inline-block;
    font-size: 15px;
    flex-direction: column; /* Stack children vertically */
    align-items: start; 
    background-color: #F1F0F0;
    color: black;
    margin-top: 15px;
    padding: 15px;
  }
  
  .bot-icon {
    height: 20px;
    width: 20px;
    margin-right: 5px;
  }

  .stop-button {
    display: block !important;
    margin-left: 5px;
  }
  .Tekticon {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 80px;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: rgb(236, 236, 236);
  }

  .stop-button:hover {
    background: #cc0000; /* darker red on hover */
  }
  .disclaimer {
    font-size: 10px;
    color: #777;
    margin-top: 20px;
    margin-bottom: 5px;
    text-align: center;
    width: 100%;            /* ensure it spans the full width of its parent */
    margin-left: auto;      /* these will center the element if its container */
    margin-right: auto;     /* isn't taking full width of its parent */
  }
  .suggestion-questions p {
    padding: 13px;
  }
  .prompt-input::placeholder {
    font-size: 14px;
}
  .prompt-input {
    font-size: 16px;
    line-height: 1.0;
    padding-right: 25%;
    overflow: scroll;
  }
  .send-button-container {
    bottom: 8px;
  } 
  .unique-form {
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto auto;
    margin: 10px;
    gap: 0px;
  } 
  .unique-select-input{
    margin: 5px;
  }
  .unique-submit-button {
    margin: 5px;
    display: block;
    padding: 15px;
    width: auto;
    font-weight: 500;
  }
  .custom-file-upload{
    margin: 5px;
  }
  .first-column{
    font-family: 'Avenir';
    align-items: flex-start;
  }
  .second-column{
    font-family: 'Avenir';
    align-items: flex-start;
    height: 100%;
  }
  body {
    
    overflow: auto;

  }
  .prompt-container {
    padding: 0px;
  }
  .disclaimer-interior{
    display: none;
  }
  .unique-form {
    display: grid;
    grid-template-rows: auto 1fr; /* Two rows */
    grid-template-columns: 1fr 1fr; /* Two columns */
    gap: 0px;
  }
  #chat-box img {
    max-width: 100%;
  }
  .custom-camera {
    display: inline-block;
    padding: 10px;
    cursor: pointer;
    border-radius: 25px;
    background-color: #9b28d9;
    color: white;
    margin: 5px;
  }
  .custom-camera img {
    width: 23px;
    height: 20px; 
  }
  .box-container {
    position: relative;
}

.sidebar-icon {
    position: absolute;
    background-color: transparent;
    border-radius: 5px;
    top: 15px;
    left: 15px;
    z-index: 100;
}

#side-bar {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.91); /* Add a semi-transparent background */
    z-index: 99;
}

.right-side {
    width: 100%;
}
.faq-icon {
  display: none;
}

}