html, body {
    height: auto;
    min-height: 100%;
    overflow-y: auto;
    font-family: 'Avenir';
}

.pricing-container {
    display: flex;
    background-color: #1A2234; /* Your primary background color */
    background-size: cover;
    background-position: center;
    background-attachment: scroll;
    flex-direction: column;
    align-items: center;
    text-align: center;
    min-height: 100vh;  /* Ensure it covers the full viewport height */
    width: 100%;
    top: 0;
    bottom: 0;
    overflow-y: auto;
    justify-content: space-between;  /* Distribute content vertically */
}

.pricing-container::before {
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('./images/body/glow-top.svg');
    background-attachment: scroll;
    background-size: cover;
    opacity: 0.7;
    background-position: center;
    position: absolute;
    z-index: 0;
  }
.pricing-title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 20px;
}

.pricing-subtitle {
    font-size: 1.2rem;
    margin-bottom: 50px;
}

.pricing-options {
    display: flex;
    gap: 35px;
    margin: 32px auto 0px;
    width: fit-content;
    justify-content: space-between; /* Ensures cards take up equal space */
}

.pricingA-card, .pricingB-card {
    width: calc((100% - 32px) / 2); /* Minus 32px to account for the gap and dividing by 2 for equal widths */
    flex: 1; /* This will ensure the cards take up equal space within the parent */
    max-height: 360px; /* Adjust based on your needs */
}

.pricingA-card {
    position: relative;
    padding: 50px;
    font-family: 'Avenir';
    display: flex;
    flex-direction: column;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    background: linear-gradient(90.83deg, rgba(196, 196, 212, 0.011) 11.84%, rgba(163, 11, 214, 0.069) 111.32%);
    border-radius: 32px;
    backdrop-filter: blur(12px);
    border: .5px solid #ffffff22;
    gap: 24px;
}
.pricingA-card button{
  display: inline-block;
  background-color: white ;
  color: black;
  outline: none;
  font-family: 'Avenir';
  border: none;
  text-decoration: none;
  padding: 10px 20px;
  font-size: 18px;
  border-radius: 25px;
  transition: 0.4s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.pricingB-card {
    position: relative;
    padding: 48px;
    font-family: 'Avenir';
    display: flex;
    flex-direction: column;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    background: linear-gradient(90.83deg, rgba(196, 196, 212, 0.053) 11.84%, rgba(164, 11, 214, 0.366) 111.32%);
    border-radius: 32px;
    backdrop-filter: blur(12px);
    gap: 24px;
}
.pricingB-card::before {
    content: "";
    position: absolute;
    inset: 0px;
    border-radius: 32px;
    border-width: 2px;
    border-style: solid;
    border-color: transparent rgb(248, 45, 214) transparent rgb(129, 51, 239);
    border-image: initial;
    background: linear-gradient(281.4deg, rgb(214, 45, 248) -2.34%, rgb(161, 51, 239) 114.41%);
    mask: linear-gradient(rgb(255, 255, 255) 0px, rgb(255, 255, 255) 0px) padding-box padding-box, linear-gradient(rgb(255, 255, 255) 0px, rgb(255, 255, 255) 0px);
-webkit-mask: linear-gradient(rgb(255, 255, 255) 0px, rgb(255, 255, 255) 0px) padding-box padding-box, linear-gradient(rgb(255, 255, 255) 0px, rgb(255, 255, 255) 0px);
mask-composite: xor;
-webkit-mask-composite: xor;
    box-sizing: border-box;
    pointer-events: none;
}


.pricingA-card:hover, .pricingB-card:hover {
    transform: translateY(-10px) scale(1.05); /* Adding a slight scale to match the description */
    box-shadow: 0 8px 15px rgba(0,0,0,0.1);
}

.pricingA-card h2, .pricingB-card h2 {
    margin-bottom: 20px;
}
.pricingA-card h3, .pricingB-card h3 {
    font-family: 'Avenir';
    font-weight: 800;
    font-size: 24px;
    line-height: 150%;
    letter-spacing: -0.01em;
    text-transform: unset;
    color: rgb(255, 255, 255);
}

.pricingA-card p, .pricingB-card ul {
    font-family: 'Avenir';
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    letter-spacing: -0.01em;
    text-transform: unset;
    color: rgba(255, 255, 255, 0.7);
}
.pricingB-card li {
    font-family: 'Avenir';
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    letter-spacing: -0.01em;
    text-transform: unset;
    color: rgb(255, 255, 255);
}
.pricingB-card ul {
    display: flex;
    flex-direction: column;
    padding: 0px;
    gap: 16px;
    margin: 0px;
}
.pricingB-card ul li {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    gap: 16px;
}
.pricing-card ul {
    list-style-type: none;
    padding-left: 0;
}

.footer-pricing {
    padding: 20px 30px; 
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 99%; 
    margin-bottom: 10px;
    border-radius: 45px;
    background-color: #2d2d2d21;
    z-index: 1;
    height: 12vh;
  }
  
.footer-sentence {
    align-items: center;
    font-size: 13px;
    margin-left: 100px;
    text-align: center;
 }
.logo-footer {
    display: block;  /* Ensuring the logo is treated as a block-level element */
    width: 120px;
    padding: 5px;  /* Making sure the logo scales if necessary */
}

.socials {
    display: flex;  /* Arranges the social icons in a row */
    gap: 25px;  /* Adds space between the icons */
}

.social-icon {
    display: inline-block;  /* Ensures each link is treated as a block-level within the flex container */
    background-color: transparent;
}
.social-icon img {
  width: 30px;
  height: auto;
  transition: 0.3s;              /* Smooth transition for hover effects */
}
.social-icon:hover img {
  opacity: 0.8;                  /* Reduces opacity to 70% when hovered */
  transform: scale(1.2);         /* Enlarges the image slightly when hovered */
}

.pricing-content {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  margin-top: 30px;
  margin-bottom: 30px;
}
.pricing-content h1 {
    font-family: 'Avenir';
    font-weight: 800;
    font-size: 40px;
    margin: 15px;
    line-height: 150%;
    letter-spacing: -0.01em;
    text-transform: unset;
    color: rgb(255, 255, 255);
}
.pricing-content h5 {
    font-family: 'Avenir';
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    letter-spacing: -0.01em;
    text-transform: unset;
    color: rgba(255, 255, 255, 0.7);
}
@media screen and (max-width: 700px) and (max-height: 1000px) { 
    .footer {
        position: absolute;
        bottom: 10px; 
        width: 95%;
        padding: 10px;
        height: 12vh; 
      }
      .footer-sentence {
        font-size: 12px;
        margin-left: 10px;
        text-align: start;
     }
      .logo-footer {
        display: none;
  
      }
      .pricing-content {
        padding: 0px 15px 15px;
        overflow-y: auto;
        margin-top: 5px;
    }
      .unlock-features {
        text-align: center;
    }
    .pricing-content h1 {
        font-family: 'Avenir';
        font-weight: 800;
        font-size: 32px;
        line-height: 150%;
        letter-spacing: -0.01em;
        text-transform: unset;
        color: rgb(255, 255, 255);
    }
    .pricing-content h5 {
        font-family: 'Avenir';
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: -0.01em;
        text-transform: unset;
        color: rgba(255, 255, 255, 0.7);
    }
    .pricing-options {
        display: flex;
        flex-direction: column;
        gap: 16px;
        justify-content: center;
        align-items: center;
        margin-top: 16px;
        margin: 32px auto 0px;
        width: 100%;
    }
    .pricingA-card {
        padding: 24px;
        border: 1px solid rgba(255, 255, 255, 0.4);
        gap: 16px;
        width: 90%;
        backdrop-filter: blur(12px);
        border-radius: 32px;
        display: flex;
        flex-direction: column;
        -webkit-box-align: center;
        align-items: center;
        text-align: center;
    }
    .pricingA-card h3, .pricingB-card h3 {
        font-family: 'Avenir';
        font-weight: 800;
        font-size: 18px;
        line-height: 150%;
        letter-spacing: -0.01em;
        text-transform: unset;
        color: rgb(255, 255, 255);
    }
    .pricingA-card p, .pricingB-card ul {
        font-family: 'Avenir';
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: -0.01em;
        margin-top: 4px;
        text-transform: unset;
        color: rgba(255, 255, 255, 0.7);
    }
    .pricingB-card {
        padding: 24px;
        gap: 16px;
        width: 90%;
        display: flex;
        position: relative;
        flex-direction: column;
        -webkit-box-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        align-items: center;
        border-radius: 32px;
        backdrop-filter: blur(12px);
    }
    .pricingB-card ul {
        display: flex;
        flex-direction: column;
        padding: 0px;
        gap: 16px;
        margin: 0px;
    }
    .pricingB-card li {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        gap: 16px;
    }
    .pricingB-card ul li {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        gap: 8px;
        font-family: 'Avenir';
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: -0.01em;
        text-transform: unset;
        color: rgb(255, 255, 255);
    }
    .svg {
        width: 16px;   /* Adjust this value as needed */
        height: 16px;  /* Adjust this value as needed */
    }
    .pricingA-card button{
        margin-top: 5px;
      }


}