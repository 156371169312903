html, body {
    height: auto;
    min-height: 100%;
    overflow-y: auto;
    font-family: 'Avenir';
}
.about-us-container  {
    display: flex;
    background-color: #1A2234; /* Your primary background color */
    background-size: cover;
    background-position: center;
    background-attachment: scroll;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-height: 100%;
    width: 100%;
    overflow-y: auto;
}
.about-us-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('./images/body/glow-top.svg');
    background-attachment: scroll;
    background-size: cover;
    opacity: 0.4;
    background-position: center;
    z-index: 0; /* Ensure the image is behind the content */
}

.about-us-content {
    align-items: center;
    position: relative;
    margin-top: 60px;
    margin-bottom: 50px;
    max-width: 75%;
    padding: 25px;
    text-align: left;
    
}
.about-us-content h3 {
    font-size: 19px;
    margin-bottom: 15px;
    color: rgb(188, 188, 188);
    font-weight: 600;

}

.about-us-content h1 {
    font-size: 33px;
    margin-bottom: 15px;
    color: white;
    font-weight: 700;
}
.about-us-content h2 {
    font-size: 17px;
    line-height: 1.5;
    font-weight: 400;
    color: rgb(188, 188, 188);
    margin-bottom: 35px;
}
.about-us-content h5 {
    font-size: 17px;
    line-height: 1.5;
    font-weight: 400;
    color: rgb(188, 188, 188);
    margin-bottom: 35px;
}

.footer-aboutus {
    padding: 20px 30px; 
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 99%; 
    margin-bottom: 10px;
    border-radius: 45px;
    background-color: #2d2d2d21;
    z-index: 1;
    height: 12vh;
  }
  

.socials {
    display: flex;  /* Arranges the social icons in a row */
    gap: 25px;  /* Adds space between the icons */
}

.social-icon {
    display: inline-block;  /* Ensures each link is treated as a block-level within the flex container */
    background-color: transparent;
}
.social-icon img {
  width: 30px;
  height: auto;
  transition: 0.3s;              /* Smooth transition for hover effects */
}
.social-icon:hover img {
  opacity: 0.8;                  /* Reduces opacity to 70% when hovered */
  transform: scale(1.2);         /* Enlarges the image slightly when hovered */
}
.Policy {
    color: #cc23d2;
}
.Policy:hover {
    color: #04cb8f;                  /* Reduces opacity to 70% when hovered */
  }

@media screen and (max-width: 700px) and (max-height: 1000px) { 
    .about-us-content {
        align-items: center;
        position: relative;
        margin-top: 30px;
        max-width: 90%;
        padding: 15px;
        text-align: left;
        
    }
    .logo-footer {
        display: none;
  
      }
    .footer-aboutus {
      bottom: 10px; 
      padding: 10px;
      height: 12vh; 
    }
    .footer-sentence {
        color: #b4b4b4;
        font-size: 12px;
        margin-left: 10px;
        text-align: start;
    }
    .social-icon img {
        width: 33px;
        height: auto;
        transition: 0.3s;              /* Smooth transition for hover effects */
      }
}